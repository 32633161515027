import { template as template_9471edca68444e5597277641e7aedc51 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_9471edca68444e5597277641e7aedc51(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_9471edca68444e5597277641e7aedc51(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_9471edca68444e5597277641e7aedc51(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
