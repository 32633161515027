import { template as template_d17b56e653ba4f3095140954fb9bf51e } from "@ember/template-compiler";
const FKText = template_d17b56e653ba4f3095140954fb9bf51e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
