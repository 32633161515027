import { template as template_557ab09ed9334664b1bd2bc5a72baf23 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_557ab09ed9334664b1bd2bc5a72baf23(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
