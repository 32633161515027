import { template as template_15541a350c43431fa8c285a9ea5004de } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_15541a350c43431fa8c285a9ea5004de(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
