import { template as template_fa9dd5064ef148e9a9494f71b90ad1fa } from "@ember/template-compiler";
const FKLabel = template_fa9dd5064ef148e9a9494f71b90ad1fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
