import { template as template_2cb9b059ae894253b46e6ec310a3ac8e } from "@ember/template-compiler";
const SidebarSectionMessage = template_2cb9b059ae894253b46e6ec310a3ac8e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
